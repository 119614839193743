import { chunk } from 'lodash-es';

/**
 * @param  images
 * @param  page
 * @param  skipSort
 * @param  columns
 * @returns rows []
 */

// eslint-disable-next-line no-unused-vars
export default function prepareImagesForMasonryGallery(
  images,
  page,
  skipSort,
  containerWidth,
  columns = 3,
) {
  // EDGE CASE: sort images from large to small
  // to prevent a large image from taking too much space
  if (!skipSort) {
    if (images.length === 4 || images.length === 7) {
      images.sort((a, b) => b.height - a.height);
    } else {
      // sort images from small to large
      images.sort((a, b) => a.height - b.height);
    }
  }
  // two dimensional array of 3 groups from small to large
  const rows = chunk(images, columns);

  rows.map((row) => {
    let totalWidth = null;
    // from each row the height of the first image
    const heightFirstImageEachRow = row[0].height;

    // init scaledWidth and scaledHeight attributes
    row.map((image) => {
      const img = image;
      img.scaledHeight = image.height;
      img.scaledWidth = image.width;
      return img;
    });

    // get scale each img and use it to set Height and Width of each image
    row.map((image) => {
      const img = image;
      const scale = heightFirstImageEachRow / image.height;
      img.scaledHeight *= scale;
      img.scaledWidth *= scale;
      totalWidth += img.scaledWidth;
      return img;
    });

    // calculate padding of each side of images
    const padding = 24 * row.length;
    const imageWidth = Math.floor((containerWidth - padding) / columns);

    // get scale based on total width taken and total width & use it to set Height and Width
    const widthScale = (imageWidth * row.length) / totalWidth;
    row.map((image) => {
      const img = image;
      img.scaledHeight = parseInt(img.scaledHeight * widthScale, 10);
      img.scaledWidth = parseInt(img.scaledWidth * widthScale, 10);
      return img;
    });
    return row;
  });
  // Reverse rows when page number is even

  return rows;
}
